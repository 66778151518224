
























































































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue } from 'vue-property-decorator';
import { IExplorerSettings } from '@/store/types/IExplorerSettings';

import i18n from '@/i18n';

@Component
export default class ExplorerMenuDisplayOptions extends Vue {
  sortList = [
    {
      Name: i18n.t('TL_SORT_DIRECTION_BOOK_ASC'),
      Value: 'BookTitle Asc',
      Icon: '&#xf062',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_BOOK_DESC'),
      Value: 'BookTitle Desc',
      Icon: '&#xf063;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_AUTHOR_ASC'),
      Value: 'BookAuthor Asc',
      Icon: '&#xf062;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_AUTHOR_DESC'),
      Value: 'BookAuthor Desc',
      Icon: '&#xf063;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_TYPE_ASC'),
      Value: 'AnnotationType Asc',
      Icon: '&#xf062;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_TYPE_DESC'),
      Value: 'AnnotationType Desc',
      Icon: '&#xf063;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_CREATED_ASC'),
      Value: 'CreatedKindle Asc',
      Icon: '&#xf062;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_CREATED_DESC'),
      Value: 'CreatedKindle Desc',
      Icon: '&#xf063;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_UPLOADED_ASC'),
      Value: 'CreatedWebsite Asc',
      Icon: '&#xf062;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_UPLOADED_DESC'),
      Value: 'CreatedWebsite Desc',
      Icon: '&#xf063;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_LOCPAGE_ASC'),
      Value: 'LocationDecimal Asc',
      Icon: '&#xf062;',
    },
    {
      Name: i18n.t('TL_SORT_DIRECTION_LOCPAGE_DESC'),
      Value: 'LocationDecimal Desc',
      Icon: '&#xf063;',
    },
  ];

  get DisplayHighlightColor() {
    return ExplorerDataModule.ExplorerSettings!.DisplayHighlightColor;
  }

  set DisplayHighlightColor(value: boolean) {
    this.saveSettings('DisplayHighlightColor', false, value);
  }

  get DisplayRecordSeparator() {
    return ExplorerDataModule.ExplorerSettings!
      .DisplayRecordSeparator;
  }

  set DisplayRecordSeparator(value: boolean) {
    this.saveSettings('DisplayRecordSeparator', false, value);
  }

  get ReadingModeOn() {
    return ExplorerDataModule.ExplorerSettings!.ReadingModeOn;
  }

  set ReadingModeOn(value: boolean) {
    this.saveSettings('ReadingModeOn', false, value);
  }

  get BookMarks() {
    return ExplorerDataModule.ExplorerSettings!.BookMarks;
  }

  set BookMarks(value: boolean) {
    this.saveSettings('BookMarks', false, value);
  }

  get Clippings() {
    return ExplorerDataModule.ExplorerSettings!.Clippings;
  }

  set Clippings(value: boolean) {
    this.saveSettings('Clippings', false, value);
  }

  get Highlights() {
    return ExplorerDataModule.ExplorerSettings!.Highlights;
  }

  set Highlights(value: boolean) {
    this.saveSettings('Highlights', false, value);
  }

  get Notes() {
    return ExplorerDataModule.ExplorerSettings!.Notes;
  }

  set Notes(value: boolean) {
    this.saveSettings('Notes', false, value);
  }

  async saveSettings(
    propertyName: string,
    reloadrecords: boolean,
    value: any,
  ) {
    // we clone the settings to avoid mutating vuex state
    const clonedSettings = Object.assign({}, this.settings) as any;
    clonedSettings[propertyName] = value;
    await ExplorerDataModule.saveExplorerSettings(
      clonedSettings as IExplorerSettings,
    );
    if (reloadrecords) {
      await ExplorerDataModule.loadRecords();
    }
  }

  get settings() {
    return ExplorerDataModule.ExplorerSettings as IExplorerSettings;
  }

  async resetToDefaults() {
    await ExplorerDataModule.resetExplorerSettings();
  }
}
