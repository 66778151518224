

































































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import addcollectionmodal from '@/components/modals/AddCollectionModal.vue';
import { IBook } from '@/store/types/IBook';
import {
  Component,
  Vue,
  Prop,
  Watch,
  Emit,
  PropSync,
} from 'vue-property-decorator';

@Component({
  components: {
    addcollectionmodal,
  },
})
export default class BookCollectionsModal extends Vue {
  @PropSync('visible') isVisible!: boolean;
  @Prop() book!: IBook;
  selectedCollections: number[] = [];

  addCollectionModalVisible = false;
  showAddCollectionModal() {
    this.addCollectionModalVisible = true;
  }

  load() {
    this.selectedCollections = ExplorerDataModule.collectionIdsForBook(
      this.book.BookId,
    );
  }

  cancel() {
    this.isVisible = false;
  }

  get collections() {
    return ExplorerDataModule.BookCollections.filter(
      (c) => c.BookCollectionID != -1,
    );
  }

  async updateBookCollections() {
    await ExplorerDataModule.updateBookCollections({
      BookId: this.book.BookId,
      CollectionIds: this.selectedCollections,
    });
    this.isVisible = false;
  }
}
