

















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import LayoutModule from '@/store/modules/LayoutModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import explorermenudisplayoptions from '@/components/menus/ExplorerMenuDisplayOptions.vue';
import explorermenucontextactions from '@/components/menus/ExplorerMenuContextActions.vue';

@Component({
  components: {
    explorermenudisplayoptions,
    explorermenucontextactions,
  },
})
export default class ExplorerContentTitlebar extends Vue {
  hideTooltips() {
    //needed for mobile
    setTimeout(() => {
      this.$root.$emit('bv::hide::tooltip');
    }, 2000);
  }

  get leftCols() {
    return this.selectedfolder!.FolderName == 'deleted' ? '6' : '8';
  }

  get rightCols() {
    return this.selectedfolder!.FolderName == 'deleted' ? '6' : '4';
  }

  // get explorersettings() {
  //   return ExplorerDataModule.ExplorerSettings;
  // }

  get leftsidebaropen() {
    return LayoutModule.leftsidebaropen;
  }

  get readingmodeon() {
    return ExplorerDataModule!.ExplorerSettings!.ReadingModeOn;
  }

  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  toggleReadingMode() {
    this.hideTooltips();
    ExplorerDataModule.toggleReadingMode();
  }

  async emptyTrash() {
    this.hideTooltips();
    await ExplorerDataModule.emptyTrash();
  }

  async restoreAll() {
    this.hideTooltips();
    await ExplorerDataModule.restoreAllRecords();
  }

  async addUnAttachedNote(bookid: number) {
    this.hideTooltips();
    await ExplorerDataModule.addUnAttachedNote(bookid);
  }
}
