








































































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import bookcollectionsmodal from '@/components/modals/BookCollectionsModal.vue';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import { IModalRequest } from '@/store/types/IModalRequest';
import { ITag } from '@/store/types/ITag';
import router from '@/router';

@Component({
  components: {
    bookcollectionsmodal,
  },
})
export default class ExplorerMenuContextActions extends Vue {
  menuHovered = false;
  bookCollectionsModalVisible = false;

  exportWizard() {}

  deleteBook() {
    ExplorerDataModule.deleteBook({
      id: Number(ExplorerUiStateModule.selectedFolder.SelectedId),
      modal: this.$bvModal,
    } as IModalRequest);
  }

  deleteTag() {
    ExplorerDataModule.deleteTag({
      TagId: Number(ExplorerUiStateModule.selectedFolder.SelectedId),
      Title: ExplorerUiStateModule.selectedFolder.SelectedTitle,
    });
  }

  get selectedItem() {
    return ExplorerUiStateModule.selectedFolder.SelectedItem;
  }

  deleteImport() {
    ExplorerDataModule.deleteImport({
      id: Number(ExplorerUiStateModule.selectedFolder.SelectedId),
      modal: this.$bvModal,
    } as IModalRequest);
  }

  showBookCollectionsModal() {
    this.bookCollectionsModalVisible = true;
  }

  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder.FolderName.toLowerCase();
  }

  get showExportMenu() {
    switch (
      ExplorerUiStateModule.selectedFolder.FolderName.toLowerCase()
    ) {
      case 'all':
        return true;
      case 'favorites':
        return true;
      case 'deleted':
        return false;
      case 'books':
        return true;
      case 'imports':
        return true;
      case 'tags':
        return true;
      case 'search':
        return true;
    }
  }

  get showMenu() {
    switch (
      ExplorerUiStateModule.selectedFolder.FolderName.toLowerCase()
    ) {
      case 'all':
        return true;
      case 'favorites':
        return true;
      case 'deleted':
        return false;
      case 'books':
        return true;
      case 'imports':
        return true;
      case 'tags':
        return true;
      case 'search':
        return true;
    }
  }

  onOver() {
    this.menuHovered = true;
  }
  onLeave() {
    this.menuHovered = false;
  }
}
